<template>
	<div class="stars-rating" :class="{notEditable: !notEditable, horizontal}">
		<div class="stars-container">
			<div
				v-for="star in 5"
				:key="star"
				class="star"
				@click="setRating(star)"
				@mouseenter="hoverStar(star)"
				@mouseleave="hoverStar(0)"
				:class="{hover: hovering && star*100 <= hovering}"
			>
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<defs>
						<linearGradient :id="'grad-'+id+'-'+star" x1="0%" y1="0%" x2="100%" y2="0%">
							<stop :offset="starFillPercentage(star)+'%'" style="stop-color:rgb(17, 191, 149);stop-opacity:1" />
							<stop :offset="starFillPercentage(star)+'%'" style="stop-color:rgb(0,0,0);stop-opacity:.3" />
						</linearGradient>
					</defs>
					<path d="M12 17.27L18.18 21L16.54 14.35L22 8.24L15.45 7.66L12 2L8.55 7.65L2 8.24L7.45 14.34L5.82 21L12 17.27Z" :fill="'url(#grad-'+id+'-'+star+')'"/>
				</svg>
			</div>
		</div>
		<div v-if="!iconsOnly && rating" :style="'--rating-color:'+ratingColor" class="row centered flex-end rating-container">
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="rating-star">
				<path d="M12 17.27L18.18 21L16.54 14.35L22 8.24L15.45 7.66L12 2L8.55 7.65L2 8.24L7.45 14.34L5.82 21L12 17.27Z" />
			</svg>
			<p class="rating">{{formattedRating}}<span v-if="!numberOnly">{{ " "+ratingPhrase }}</span></p>
		</div>
	</div>
</template>

<script>
import {getRandomAlphanumericString} from "@/shared/helpers/strings";
import {useLanguageStore} from "@/shared/stores";

export default {
	name: "StarsRating",
	setup() {
		const languageStore = useLanguageStore();
		return {getString: languageStore.getString}
	},
	emits: ["selected-rating"],
	props: {
		rating: {
			type: Number,
			default: 0,
			validators: [value => value >= 0 && value <= 500]
		},
		iconsOnly: {
			type: Boolean,
			default: false
		},
		numberOnly: {
			type: Boolean,
			default: false
		},
		notEditable: {
			type: Boolean,
			default: false
		},
		horizontal: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			hovering: 0,
			id: getRandomAlphanumericString(10),
		}
	},
	computed: {
		formattedRating() {
			if(!this.rating) { return '0'; }
			let finalNumber = Math.round(Number(this.rating || 0) / 10) / 10;
			return finalNumber.toFixed(1);
		},
		ratingPhrase() {
			let startRating = Number(this.rating);
			let finalPhrase = '';
			if(startRating <= 200) {
				finalPhrase = this.getString('rating_1');
			} else if(startRating <= 300) {
				finalPhrase = this.getString('rating_2');
			} else if(startRating < 400) {
				finalPhrase = this.getString('rating_3');
			} else if(startRating < 460) {
				finalPhrase = this.getString('rating_4');
			} else if(startRating <= 500) {
				finalPhrase = this.getString('rating_5');
			}
			return finalPhrase;
		},
		ratingColor() {
			let startRating = Number(this.rating || 0);
			let finalColor = 'rgba(0,0,0,.5)';

			if(startRating <= 200) {
				finalColor = 'rgba(255,0,0, 0.8)';
			} else if(startRating <= 300) {
				finalColor = 'rgba(193,12,112, .8)';
			} else if(startRating < 400) {
				finalColor = 'rgba(7, 82, 163, .8)';
			} else if(startRating < 460) {
				finalColor = 'rgb(22, 177, 211, .8)';
			} else if(startRating <= 500) {
				finalColor = 'rgb(17, 191, 149)';
			}
			return finalColor;
		},
	},
	methods: {
		setRating(starIndex) {
			if(!this.notEditable) {
				this.$emit("selected-rating", starIndex * 100);
			}
		},
		hoverStar(starIndex) {
			if(!this.notEditable) {
				this.hovering = starIndex * 100;
			}
		},
		starFillPercentage(starIndex) {
			let star = starIndex * 100;
			if ((this.hovering && this.hovering >= star) ||
				(this.rating && this.rating >= star)) {
				return 100;
			}
			if (this.rating && this.rating < star && this.rating > star - 100) {
				return 100 - (star - this.rating);
			}
			return 0;
		},
	},
}
</script>

<style lang="scss" scoped>
.stars-rating {
	width: 120px;

	.stars-container, .rating-container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		max-width: 100%;
	}

	.star {
		cursor: pointer;
		width: 24px;
		height: 24px;

		* { pointer-events: none; }

		svg path {
			transition: fill .1s $transition, opacity .1s $transition;
			opacity: 1;
		}

		&.hover {
			svg path {
				fill: rgb(17, 191, 149);
			}
		}
	}

	&.not-editable {
		.star {
			cursor: default;
		}
	}

	&.horizontal {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: auto;

		.rating-container {
			padding-left: 5px;

			.rating-star {
				display: none;
			}
		}
	}

	.rating-container {

		--rating-color: var(--color-primary);

		height: 20px;
		justify-content: flex-start;
		padding-left: 3px;

		svg {
			opacity: .8;
			width: 14px;
			height: 14px;
			path {
				fill: var(--rating-color);
			}
		}
		.rating {
			opacity: .6;
			line-height: 20px;
			font-size: 12px;
			padding-left: 3px;
			min-width: 25px;
			text-align: right;

			span {
				font-size: 10px;
			}
		}
	}
}
</style>